import React from "react"
import classnames from "classnames"

import css from "./index.module.scss"

export default ({ className }) => (
  <svg
    className={classnames(className, css.icon)}
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className={css.icon_color}
        transform="translate(-852.000000, -509.000000)"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 478.000000)">
          <g transform="translate(852.000000, 31.000000)">
            <path d="M20,1.875 L20,13.125 C20,14.1601562 19.1601562,15 18.125,15 L16.25,15 L16.25,6.875 C16.25,5.15234375 14.8476563,3.75 13.125,3.75 L5,3.75 L5,1.875 C5,0.83984375 5.83984375,0 6.875,0 L18.125,0 C19.1601562,0 20,0.83984375 20,1.875 Z M15,6.875 L15,18.125 C15,19.1601562 14.1601562,20 13.125,20 L1.875,20 C0.83984375,20 0,19.1601562 0,18.125 L0,6.875 C0,5.83984375 0.83984375,5 1.875,5 L13.125,5 C14.1601562,5 15,5.83984375 15,6.875 Z M12.34375,7.96875 C12.34375,7.7109375 12.1328125,7.5 11.875,7.5 L2.96875,7.5 C2.7109375,7.5 2.5,7.7109375 2.5,7.96875 L2.5,10 L12.34375,10 L12.34375,7.96875 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
