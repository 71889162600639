import React from "react"
import classnames from "classnames"

import css from "./index.module.scss"

export default ({ className }) => (
  <svg
    className={classnames(className, css.icon)}
    width="16px"
    height="20px"
    viewBox="0 0 16 20"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className={css.icon_color}
        transform="translate(-179.000000, -509.000000)"
        fill="#286FFA"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 478.000000)">
          <g transform="translate(179.000000, 31.000000)">
            <path d="M10.1333333,5.625 L10.1333333,1.25 C10.1333333,0.559648438 10.7004437,0 11.4,0 C12.0995562,0 12.6666667,0.559648438 12.6666667,1.25 L12.6666667,5.625 L10.1333333,5.625 Z M14.5666667,6.25 L0.633333333,6.25 C0.283535417,6.25 0,6.52980469 0,6.875 L0,8.125 C0,8.47019531 0.283535417,8.75 0.633333333,8.75 L1.26666667,8.75 L1.26666667,10 C1.26666667,13.0236719 3.44252292,15.5457422 6.33333333,16.1248437 L6.33333333,20 L8.86666667,20 L8.86666667,16.1248437 C11.7574771,15.5457422 13.9333333,13.0236719 13.9333333,10 L13.9333333,8.75 L14.5666667,8.75 C14.9164646,8.75 15.2,8.47019531 15.2,8.125 L15.2,6.875 C15.2,6.52980469 14.9164646,6.25 14.5666667,6.25 Z M5.06666667,5.625 L5.06666667,1.25 C5.06666667,0.559648438 4.49955625,0 3.8,0 C3.10044375,0 2.53333333,0.559648438 2.53333333,1.25 L2.53333333,5.625 L5.06666667,5.625 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
