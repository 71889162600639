import React, { Component } from "react"
import classnames from "classnames"

import Grid from "../../Grid"
import IntegrationIcon from "./IntegrationIcon"
import AutomationIcon from "./AutomationIcon"
import AnalyticsIcon from "./AnalyticsIcon"
import TemplatesIcon from "./TemplatesIcon"
import ChannelsIcon from "./ChannelsIcon"
import SupportIcon from "./SupportIcon"

import css from "./index.module.scss"

export const INTEGRATION = "integration"
export const MARKETINGAPP = "marketingapp"
export const AUTOMATION = "automation"
export const DATA = "data"
export const TEMPLATES = "templates"
export const CHANNELS = "channels"
export const SUPPORT = "support"

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.state = {
      fixed: false,
    }
  }

  navigateTo = (e) => {
    e.preventDefault()
    const target = e.currentTarget
    const element = document.getElementById(target.hash.substring(1))
    const navigation = document.getElementById("navigation")
    if (!element) return
    window.scrollTo({
      left: 0,
      top: element.offsetTop - (navigation.clientHeight + 20),
      behavior: "smooth",
    })
  }

  componentDidMount() {
    this.floatObserver = new IntersectionObserver(
      (entries) => {
        window.requestAnimationFrame(() => {
          const [container] = entries
          this.setState({ fixed: !container.isIntersecting })
        })
      },
      {
        threshold: 0.0,
      }
    )
    this.floatObserver.observe(this.container.current)
  }

  componentWillUnmount() {
    if (this.floatObserver) {
      this.floatObserver.unobserve(this.container.current)
      this.floatObserver = null
    }
  }

  render() {
    const { className, highlight } = this.props
    return (
      <div
        ref={this.container}
        className={classnames(className, css.container)}
      >
        <Grid
          className={classnames(css.navigation, {
            [css.fixed]: this.state.fixed,
          })}
        >
          <ul id="navigation">
            <li
              className={classnames({
                [css.current]: [INTEGRATION, MARKETINGAPP].includes(highlight),
              })}
            >
              <a href="#integration" onClick={this.navigateTo}>
                <IntegrationIcon /> Integration
              </a>
            </li>
            <li
              className={classnames({
                [css.current]: highlight === AUTOMATION,
              })}
            >
              <a href="#automation" onClick={this.navigateTo}>
                <AutomationIcon /> Automation
              </a>
            </li>
            <li
              className={classnames({
                [css.current]: highlight === DATA,
              })}
            >
              <a href="#data" onClick={this.navigateTo}>
                <AnalyticsIcon /> Analytics
              </a>
            </li>
            <li
              className={classnames({
                [css.current]: highlight === TEMPLATES,
              })}
            >
              <a href="#templates" onClick={this.navigateTo}>
                <TemplatesIcon />
                Templates
              </a>
            </li>
            <li
              className={classnames({
                [css.current]: highlight === CHANNELS,
              })}
            >
              <a href="#channels" onClick={this.navigateTo}>
                <ChannelsIcon /> Channels
              </a>
            </li>
            <li
              className={classnames({
                [css.current]: highlight === SUPPORT,
              })}
            >
              <a href="#support" onClick={this.navigateTo}>
                <SupportIcon />
                Support
              </a>
            </li>
          </ul>
        </Grid>
      </div>
    )
  }
}

export default Navigation
