import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Logos from "../../../Logos"

const query = graphql`
  query {
    logo_misfitsmarket: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/misfitsmarket.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    logo_cirkul: file(
      relativePath: { eq: "images/referral_programs/customer_logos/cirkul.png" }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    logo_smol: file(
      relativePath: { eq: "images/referral_programs/customer_logos/smol.png" }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    logo_wishbeer: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/wishbeer.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const ReferralProgramsLogos = () => {
  const {
    logo_misfitsmarket,
    logo_cirkul,
    logo_smol,
    logo_wishbeer,
  } = useStaticQuery(query)
  const imageKeys = [logo_misfitsmarket, logo_cirkul, logo_smol, logo_wishbeer]
  const logos = imageKeys.map((key) => key.childImageSharp.fixed)
  return <Logos logos={logos} />
}

export default ReferralProgramsLogos
