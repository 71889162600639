import React from "react"

import Layout from "../components/Layout"
import { CardListSection } from "../components/Sections/ReferralPrograms/CardList"
import Logos from "../components/Sections/ReferralPrograms/Logos"
import Testimonial from "../components/Sections/ReferralPrograms/Testimonial"
import Integrations from "../components/Sections/ReferralPrograms/Integrations"

const metadata = {
  title: "Create a Referral Program for Any Store | ReferralCandy",
  description:
    "ReferralCandy powers referral marketing programs for online stores of all shapes and sizes. Learn from some of our top-performing referral programs. Start your free trial today",
}

const Page = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <CardListSection />
      <Logos />
      <Testimonial />
      <Integrations />
    </Layout>
  )
}

export default Page
