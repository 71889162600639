import React from "react"
import classnames from "classnames"

import css from "./index.module.scss"

export default ({ className }) => (
  <svg
    className={classnames(className, css.icon)}
    width="26px"
    height="20px"
    viewBox="0 0 26 20"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className={css.icon_color}
        transform="translate(-1010.000000, -509.000000)"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 478.000000)">
          <g transform="translate(1010.000000, 31.000000)">
            <path d="M18.5714286,7.14285714 C18.5714286,3.19642857 14.4151786,0 9.28571429,0 C4.15625,0 0,3.19642857 0,7.14285714 C0,8.67410714 0.629464286,10.0848214 1.69642857,11.25 C1.09821429,12.5982143 0.111607143,13.6696429 0.0982142857,13.6830357 C1.18952467e-16,13.7857143 -0.0267857143,13.9375 0.03125,14.0714286 C0.0892857143,14.2053571 0.214285714,14.2857143 0.357142857,14.2857143 C1.99107143,14.2857143 3.34375,13.7366071 4.31696429,13.1696429 C5.75446429,13.8705357 7.45535714,14.2857143 9.28571429,14.2857143 C14.4151786,14.2857143 18.5714286,11.0892857 18.5714286,7.14285714 Z M24.0178571,16.9642857 C25.0848214,15.8035714 25.7142857,14.3883929 25.7142857,12.8571429 C25.7142857,9.87053571 23.3258929,7.3125 19.9419643,6.24553571 C19.9821429,6.54017857 20,6.83928571 20,7.14285714 C20,11.8705357 15.1919643,15.7142857 9.28571429,15.7142857 C8.80357143,15.7142857 8.33482143,15.6785714 7.87053571,15.6294643 C9.27678571,18.1964286 12.5803571,20 16.4285714,20 C18.2589286,20 19.9598214,19.5892857 21.3973214,18.8839286 C22.3705357,19.4508929 23.7232143,20 25.3571429,20 C25.5,20 25.6294643,19.9151786 25.6830357,19.7857143 C25.7410714,19.65625 25.7142857,19.5044643 25.6160714,19.3973214 C25.6026786,19.3839286 24.6160714,18.3169643 24.0178571,16.9642857 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
