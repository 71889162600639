import React from "react"
import classnames from "classnames"

import css from "./index.module.scss"

export default ({ className, icon, title, detail }) => (
  <div className={classnames(className, css.feature_detail)}>
    <img src={icon} alt={title} />
    <h3>{title}</h3>
    <div className={css.detail}>{detail}</div>
  </div>
)
