import React from "react"
import { IntegrationPartners } from "../../../Sections/FeaturesIntegration"
import Grid from "../../../Grid"

import css from "./index.module.scss"

const Integrations = () => {
  return (
    <Grid className={css.container}>
      <h2 className={css.header}>
        Integrate seamlessly
        <br />
        with all ecommerce platforms
      </h2>
      <IntegrationPartners />
    </Grid>
  )
}

export default Integrations
