import React from "react"
import classnames from "classnames"
import css from "./index.module.scss"

export default ({ className }) => (
  <svg
    className={classnames(className, css.icon)}
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className={css.icon_color}
        transform="translate(-360.000000, -509.000000)"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 478.000000)">
          <g transform="translate(360.000000, 31.000000)">
            <path d="M8.75,3.75 L9.375,2.5 L10.625,1.875 L9.375,1.25 L8.75,0 L8.125,1.25 L6.875,1.875 L8.125,2.5 L8.75,3.75 Z M3.125,6.25 L4.16640625,4.16679688 L6.25,3.125 L4.16640625,2.08320313 L3.125,0 L2.08359375,2.08320313 L0,3.125 L2.08359375,4.16679688 L3.125,6.25 Z M16.875,11.25 L15.8335937,13.3332031 L13.75,14.375 L15.8335938,15.4167969 L16.875,17.5 L17.9164063,15.4167969 L20,14.375 L17.9164062,13.3332031 L16.875,11.25 Z M19.6335937,3.68085938 L16.3191406,0.36640625 C16.0753906,0.121875 15.7554687,0 15.4355469,0 C15.115625,0 14.7957031,0.121875 14.5515625,0.36640625 L0.36640625,14.5515625 C-0.121875,15.0398438 -0.121875,15.83125 0.36640625,16.3191406 L3.68085937,19.6335938 C3.925,19.8777344 4.24492188,19.9996094 4.56445313,19.9996094 C4.884375,19.9996094 5.20429687,19.8777344 5.4484375,19.6335938 L19.6335937,5.44804688 C20.121875,4.96054688 20.121875,4.16875 19.6335937,3.68085938 Z M14.0410156,7.94765625 L12.0523438,5.95898438 L15.4351563,2.57617188 L17.4238281,4.56484375 L14.0410156,7.94765625 L14.0410156,7.94765625 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
