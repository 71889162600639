import React from "react"
import classnames from "classnames"

import css from "./index.module.scss"

export default ({ className }) => (
  <svg
    className={classnames(className, css.icon)}
    width="20px"
    height="19px"
    viewBox="0 0 20 19"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className={css.icon_color}
        transform="translate(-531.000000, -510.000000)"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 478.000000)">
          <g transform="translate(531.000000, 32.000000)">
            <path d="M19.3695971,10.35 L10.6776557,10.35 L16.4663004,16.0292031 C16.6875458,16.2462656 17.0516484,16.263875 17.2791209,16.0536406 C18.6967033,14.7433594 19.6717949,12.9770312 19.9578755,10.9914844 C20.0069597,10.6515156 19.7194139,10.35 19.3695971,10.35 L19.3695971,10.35 Z M18.7897436,8.02125 C18.4879121,3.72815625 14.9912088,0.2975625 10.6153846,0.0014375 C10.2813187,-0.021203125 10,0.25515625 10,0.583625 L10,8.625 L18.1967033,8.625 C18.5315018,8.625 18.8128205,8.349 18.7897436,8.02125 Z M8.24175824,10.35 L8.24175824,1.82239062 C8.24175824,1.4791875 7.93443223,1.19707812 7.58827839,1.24523437 C3.22307692,1.85042187 -0.113553114,5.591875 0.0417582418,10.0757969 C0.201465201,14.6808281 4.24285714,18.4571406 8.93882784,18.3992813 C10.7849817,18.3766406 12.4908425,17.7930156 13.8934066,16.8169531 C14.1827839,16.6157031 14.2018315,16.19775 13.9509158,15.9515781 L8.24175824,10.35 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
