import React from "react"
import classnames from "classnames"
import css from "./index.module.scss"

export default ({ className }) => (
  <svg
    className={classnames(className, css.icon)}
    width="20px"
    height="17px"
    viewBox="0 0 20 17"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className={css.icon_color}
        transform="translate(-689.000000, -511.000000)"
        fill="#637381"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 478.000000)">
          <g transform="translate(689.000000, 33.000000)">
            <path d="M18.125,0 L1.875,0 C0.839453125,0 0,0.81546875 0,1.82142857 L0,15.1785714 C0,16.1845312 0.839453125,17 1.875,17 L18.125,17 C19.1605469,17 20,16.1845312 20,15.1785714 L20,1.82142857 C20,0.81546875 19.1605469,0 18.125,0 Z M8.75,14.5714286 L2.5,14.5714286 L2.5,4.85714286 L8.75,4.85714286 L8.75,14.5714286 Z M17.5,14.5714286 L11.25,14.5714286 L11.25,4.85714286 L17.5,4.85714286 L17.5,14.5714286 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
