import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Testimonial from "../../../Testimonial"

const content = {
  key: "videoblocks",
  quote:
    "Great support, fast, precise. And I also love this app, it does what you need to run a successful referral campaign.",
  cite: "Joel Holland",
  position: "CEO, Videoblocks",
  url: "/case-studies/videoblocks",
}

const query = graphql`
  query {
    showcase: file(
      relativePath: { eq: "images/testimonials/picture-videoblocks.png" }
    ) {
      childImageSharp {
        fixed(width: 305) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    avatar: file(
      relativePath: { eq: "images/testimonials/avatar-videoblocks.png" }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const ReferralProgramsTestimonial = () => {
  const { showcase, avatar } = useStaticQuery(query)
  return <Testimonial showcase={showcase} avatar={avatar} content={content} />
}

export default ReferralProgramsTestimonial
